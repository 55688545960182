import React, { useEffect, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import bullionStats from "../assets/bullioStats.png";
import smartLogo from "../assets/smartLogo.png";
import { Box, Fade, LinearProgress, Modal, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv } from "../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpired";
import SubscriptionExpired from "./SubscriptionExpired";
const { makeStyles } = require("@mui/styles");
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;
const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;
let a = 10;
const useStyles = makeStyles((theme) => ({
  mainBody: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    height: "33%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  time: {
    width: "100%",
    height: "6%",
    border: "1px solid #796225",
    boxSizing: "border-box",
    borderRadius: "10px",
    backgroundColor: "#221F19",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  banner: {
    height: "36%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #796225",
    boxSizing: "border-box",
    borderRadius: "15px",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  stat: {
    height: "15%",
    gridArea: "stat",
    backgroundColor: "#221F19",
    borderRadius: "15px",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #796225",
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  spotRate: {
    backgroundColor: "#221F19",
    border: "1px solid #796225",
    boxSizing: "border-box",
    display: "flex",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "15px",
    color: "#FFFFFF",
    width: "100%",
    height: "100%",
  },
  spotRateCol: {
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // justifyContent: "space-between",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },

  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  header: {
    height: "28%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoImg: {
    width: "100%",
    alignItems: "center",
    height: "100%",
    objectFit: "contain",
  },

  commoditieTable: {
    gridArea: "commoditieTable",
    height: "67.2%",
    display: "flex",
    flexDirection: "column",
  },
  table: {
    gridArea: "table",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#D9B450",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px 15px 0px 0px",
    alignItems: "center",
    height: "17%",
    color: "#1F1D1D",
    border: "1px #796225 solid",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "83%",
    flexDirection: "column",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "16.6%",
    flexGrow: 1,
    gridArea: "tableRow1",
    backgroundColor: "#221F19",
    display: "flex",
    borderBottom: "1px #796225 solid",
    borderRight: "1px #796225 solid",
    borderLeft: "1px #796225 solid",
  },

  updates: {
    color: "#fff",
    display: "flex",
    color: "#FFFFFF",
    height: "7vh",
    marginBottom: "2rem",
  },

  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#221F19",
    width: "100%",
    borderTop: "1px solid #796225",
    borderBottom: "1px solid #796225",
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousSilver, setPreviousSilver] = useState();
  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousGold, setPreviousGold] = useState();
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setPreviousGold(gold);
        setGold({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
      socket.on("silver-rate-change", (data) => {
        setPreviousSilver(silver);
        setSilver({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
    }
  }, [gold, silver, socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    handleloginChecking();
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handlecheckSubscriptionExpierd();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 20 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#266AAA";
  const sellersColor = "#C92136";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = () => {
    return moment(new Date()).format("dddd DD MMM YYYY - hh:mm a");
  };

  const handleloginChecking = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await loginChecking(deviceId);
    if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
      setOpenlimitExpierd(true);
    } else if (res.status === false) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res?.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };
  const handleLogout = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  return (
    <div
      style={{
        backgroundColor: `#181714`,
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#8C003D",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <Box sx={{ width: "50%", height: "100%" }}>
                <img
                  className={classes.logoImg}
                  src={smartLogo}
                  alt="amiceLogo"
                />
              </Box>
            </Box>
            <Box className={classes.time}>
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1.2vw",
                  letterSpacing: ".28vw",
                }}
              >
                {formatTime()}
              </Typography>
            </Box>
            <Box className={classes.banner}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "100%",
                  width: "100%",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "30vh",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>

            <Box className={classes.stat}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.2vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: "1.3vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#266AAA"
                          : "#C92136",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1.2vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1.3vw", color: "#266AAA" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>
                  <Typography sx={{ fontSize: "1.3vw", color: "#C92136" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.header}>
              <Box className={classes.spotRate}>
                <Box className={classes.spotRateCol}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", fontWeight: "bold" }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                      >
                        {displayBidOrBuy.bidOrBuy.toUpperCase()}
                      </Typography>
                      <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", fontWeight: "bold" }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                      >
                        {displayBidOrBuy.askOrSell.toUpperCase()}
                      </Typography>
                      <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "60%",
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                        fontWeight: "bold",
                        p: 0,
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                        letterSpacing: "3px",
                      }}
                    >
                      GOLD
                      <br />
                      <Typography
                        sx={{
                          fontSize: ".9vw",
                          fontWeight: "bold",
                          flexBasis: 0,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        SPOT RATE
                      </Typography>
                    </Typography>

                    <Box className={classes.spotRateBoxGoldRow2Col1}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.bid).toFixed(2) <
                            Number(previousGold?.bid).toFixed(2)
                              ? "#C92136"
                              : Number(gold?.bid).toFixed(2) >
                                Number(previousGold?.bid).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "7.3vw",
                          height: "7vh",
                          display: "flex",
                          alignItems: "center",
                          px: 1.5,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "2vw", fontWeight: "bold" }}
                        >
                          {(
                            Number(spread.goldBidSpread) + Number(gold.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          // pl: 1,
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#C92136",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            Low
                          </Typography>
                        </Box>

                        <Typography style={{ fontSize: "1vw" }}>
                          {(
                            Number(spread.goldLowSpread) + Number(gold.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxGoldRow2Col2}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.ask).toFixed(2) <
                            Number(previousGold?.ask).toFixed(2)
                              ? "#C92136"
                              : Number(gold?.ask).toFixed(2) >
                                Number(previousGold?.ask).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          width: "7.3vw",
                          height: "7vh",
                          display: "flex",
                          alignItems: "center",
                          px: 1.5,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "2vw", fontWeight: "bold" }}
                        >
                          {(
                            Number(spread.goldAskSpread) + Number(gold.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#266AAA",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            High
                          </Typography>
                        </Box>
                        <Typography style={{ fontSize: "1vw" }}>
                          {(
                            Number(spread.goldHighSpread) +
                            Number(gold.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.spotRateCol}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "30%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        fontWeight: "bold",
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", fontWeight: "bold" }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                      >
                        {displayBidOrBuy.bidOrBuy.toUpperCase()}
                      </Typography>
                      <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          color: "#383535",
                          background: "#FFFFFF",
                          px: "7px",
                          display: "inline-block",
                          width: "fit-content",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "1vw", fontWeight: "bold" }}
                        >
                          $
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                      >
                        {displayBidOrBuy.askOrSell.toUpperCase()}
                      </Typography>
                      <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "60%",
                      display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                        flexBasis: 0,
                        flexGrow: 0.8,
                        flexShrink: 1,
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      SILVER
                      <Typography
                        sx={{
                          fontSize: ".9vw",
                          flexBasis: 0,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        SPOT RATE
                      </Typography>
                    </Typography>

                    <Box className={classes.spotRateBoxSilverRow2Col1}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(silver?.bid).toFixed(2) <
                            Number(previousSilver?.bid).toFixed(2)
                              ? "#C92136"
                              : Number(silver?.bid).toFixed(2) >
                                Number(previousSilver?.bid).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          px: 1.5,
                          width: "7.3vw",
                          height: "7vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "2vw" }}>
                          &nbsp;&nbsp;
                          {(
                            Number(spread.silverBidSpread) + Number(silver.bid)
                          ).toFixed(2)}
                          &nbsp;&nbsp;
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#C92136",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography sx={{ fontSize: "1vw", mx: "auto" }}>
                            Low
                          </Typography>
                        </Box>
                        <Typography sx={{ fontSize: "1vw", mx: "auto" }}>
                          &nbsp;
                          {(
                            Number(spread.silverLowSpread) +
                            Number(silver.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxSilverRow2Col2}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(silver?.ask).toFixed(2) <
                            Number(previousSilver?.ask).toFixed(2)
                              ? "#C92136"
                              : Number(silver?.ask).toFixed(2) >
                                Number(previousSilver?.ask).toFixed(2)
                              ? "#266AAA"
                              : "#221F19",
                          border: "1px solid #FFFFFF",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          px: 1.5,
                          width: "7.3vw",
                          height: "7vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "2vw" }}>
                          &nbsp;&nbsp;
                          {(
                            Number(spread.silverAskSpread) + Number(silver.ask)
                          ).toFixed(2)}
                          &nbsp;&nbsp;
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          mt: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            background: "#266AAA",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            High
                          </Typography>
                        </Box>
                        &nbsp;
                        <Typography style={{ fontSize: "1vw" }}>
                          {(
                            Number(spread.silverHighSpread) +
                            Number(silver.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "start",
                      fontSize: "2.2vw",
                      fontWeight: "bold",
                      paddingLeft: "30px",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2.2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          // maxHeight: commodities.length > 6 ? "16.6%" : "",
                          borderRadius:
                            idx === commodities.length - 1 &&
                            "0px 0px 15px 15px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2.2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2.2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: "5.5vw",
                              // paddingLeft: { lg: "5.5vw", md: "5vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2.2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: "4.8vw",
                              // paddingLeft: { lg: "4.5vw", md: "4.5vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver.bid
                                : gold.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2.2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: "4.1vw",
                              // paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver.ask
                                : gold.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
            <Marquee
              speed={50}
              delay="2"
              style={{ height: "100%" }}
              gradient={false}
            >
              {news?.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    style={{
                      fontSize: "1.8vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{item.newsTitle}</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                    <span style={{ paddingRight: "50px", paddingLeft: "50px" }}>
                      {index === news.length - 1 ? "" : "|"}
                    </span>
                  </div>
                );
              })}
            </Marquee>
            <LogoutIcon
              sx={{ fontSize: "40px", color: "#FFFFF", cursor: "pointer" }}
              onClick={handleLogout}
            />
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal logout={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={tv?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleLogout} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
